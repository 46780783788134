import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { PickState } from './types';
import * as api from 'services/api';

const initialState: PickState = {
  picks: '',
  loading: false,
};

export const getPicks = createAsyncThunk('picks/get', async () => {
  const response = await api.getPicks();

  return response.data;
});

export const savePicks = createAsyncThunk(
  'picks/save',
  async (content: string) => {
    const response = await api.savePicks(content);

    return response.data;
  }
);

export const picksSlice = createSlice({
  name: 'picks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPicks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPicks.fulfilled, (state, action) => {
        const { pick } = action.payload;
        state.loading = false;
        state.picks = pick.content;
      })
      .addCase(getPicks.rejected, (state) => {
        state.loading = false;
      })
      .addCase(savePicks.pending, (state) => {
        state.loading = true;
      })
      .addCase(savePicks.fulfilled, (state, action) => {
        const { pick } = action.payload;
        state.loading = false;
        state.picks = pick.content;
      })
      .addCase(savePicks.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default picksSlice.reducer;
