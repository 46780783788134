import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AccountState, PayForPlanParams, User } from './types';
import * as api from 'services/api';

const initialState: AccountState = {
  profile: {
    plan: '',
    paymentVerified: false,
    name: '',
    email: '',
    address: '',
    brand: '',
    lastNumber: '',
    expYear: 0,
    expMonth: 0,
  },
  user: {
    email: '',
    name: '',
    picture: '',
    permission: 'user',
  },
  isAuthenticating: false,
  isAuthenticated: false,
  loading: false,
};

export const login = createAsyncThunk('account/login', async (user: User) => {
  const response = await api.login(user);

  return response.data;
});

export const getProfile = createAsyncThunk(
  'account/getProfile',
  async (email: string) => {
    const response = await api.getProfile(email);

    return response.data;
  }
);
export const saveAccount = createAsyncThunk(
  'account/saveAccount',
  async (account: {
    name: string;
    address: string;
    email: string;
    profileEmail: string;
  }) => {
    const response = await api.saveAccount(account);
    return response.data;
  }
);
export const payForPlan = createAsyncThunk(
  'account/payForPlan',
  async (payment: PayForPlanParams) => {
    const response = await api.payForPlan(payment);

    return response.data;
  }
);

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    signout: (state) => {
      state.profile = initialState.profile;
      state.user = initialState.user;
      state.isAuthenticated = false;
      localStorage.removeItem('token');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        const { user, token } = action.payload;
        state.loading = false;
        state.user = user;
        state.profile = user.profile;
        state.isAuthenticated = true;
        state.isAuthenticating = false;
        localStorage.setItem('token', token);
      })
      .addCase(login.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.loading = false;
        const { profile } = action.payload;
        state.profile = profile;
      })
      .addCase(getProfile.rejected, (state) => {
        state.loading = false;
      })
      .addCase(saveAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveAccount.fulfilled, (state, action) => {
        state.loading = false;
        const {
          user: { profile },
        } = action.payload;
        state.profile = profile;
      })
      .addCase(saveAccount.rejected, (state) => {
        state.loading = false;
      })
      .addCase(payForPlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(payForPlan.fulfilled, (state, action) => {
        state.loading = false;
        const { type } = action.payload;
        state.profile.plan = type;
      })
      .addCase(payForPlan.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const signout = accountSlice.actions.signout;

export default accountSlice.reducer;
