import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';

import { User } from 'store/account/types';

const baseUrl = process.env.REACT_APP_API_URL;

interface SaveAccountParams {
  email: string;
  name: string;
  address?: string;
  profileEmail?: string;
}
interface PayForPlanParams {
  token: string;
  type: string;
  plan: string;
  email: string;
}

const createAxiosInstance = (baseURL: string, timeout: number) => {
  const instance: AxiosInstance = axios.create({
    baseURL,
  });

  instance.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
    return config;
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const msg =
        error.response?.data?.msg ||
        error.response?.data?.error ||
        'Failed to connect to server';
      toast.error(msg);
    }
  );

  return instance;
};

const api = createAxiosInstance(baseUrl || '', 30000);

export const login = (user: User) =>
  api.post(`${baseUrl}/api/account/login`, user);
export const saveAccount = (account: SaveAccountParams) =>
  api.post(`${baseUrl}/api/account`, account);
export const payForPlan = (plan: PayForPlanParams) =>
  api.post(`${baseUrl}/api/account/subscribe`, plan);
export const getProfile = (email: string) =>
  api.get(`${baseUrl}/api/account/user/${email}`);
export const getPicks = () => api.get(`${baseUrl}/api/pick`);
export const savePicks = (content: string) =>
  api.post(`${baseUrl}/api/pick`, { content });
