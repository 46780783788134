import React from 'react';
import { Navigate, Outlet } from 'react-router';
import { Route, Routes, useLocation } from 'react-router-dom';
import loadable from '@loadable/component';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/store';

const HomePage = loadable(() => import('pages/Home'));
const AboutPage = loadable(() => import('pages/About'));
const AdminPage = loadable(() => import('pages/Admin'));
const SubscriptionPage = loadable(() => import('pages/Subscription'));
const PickPage = loadable(() => import('pages/Pick'));
const LocksNFTPage = loadable(() => import('pages/LocksNFT'));
const ProfilePage = loadable(() => import('pages/Profile'));
const LoginPage = loadable(() => import('pages/Login'));
const PrivacyPage = loadable(() => import('pages/Privacy'));
const TermsPage = loadable(() => import('pages/Terms'));

export enum RouteLink {
  Home = '/',
  About = '/about',
  Admin = '/admin',
  Subscriptions = '/subscriptions',
  Picks = '/picks',
  LocksNFT = '/locks-nft',
  Login = '/login',
  Profile = '/profile',
  Privacy = '/privacy',
  Terms = '/terms',
}

const AdminRoute = () => {
  const { user, isAuthenticated } = useAppSelector(
    (state: RootState) => state.account
  );
  const location = useLocation();
  console.log(user);

  return isAuthenticated ? (
    user.permission === 'admin' ? (
      <Outlet />
    ) : (
      <Navigate replace to={RouteLink.Home} />
    )
  ) : (
    <Navigate
      replace
      to={RouteLink.Login}
      state={{ path: location.pathname }}
    />
  );
};

const PrivateRoute = () => {
  const user = useAppSelector((state: RootState) => state.account.user);
  const location = useLocation();

  return user ? (
    <Outlet />
  ) : (
    <Navigate
      replace
      to={RouteLink.Login}
      state={{ path: location.pathname }}
    />
  );
};

const AppRoutes = () => (
  <Routes>
    <Route path={RouteLink.Home} element={<HomePage />} />
    <Route path={RouteLink.About} element={<AboutPage />} />
    <Route path={RouteLink.Subscriptions} element={<SubscriptionPage />} />
    <Route path={RouteLink.Picks} element={<PickPage />} />
    <Route path={RouteLink.LocksNFT} element={<LocksNFTPage />} />
    <Route path={RouteLink.Profile} element={<ProfilePage />} />
    <Route path={RouteLink.Login} element={<LoginPage />} />
    <Route path={RouteLink.Privacy} element={<PrivacyPage />} />
    <Route path={RouteLink.Terms} element={<TermsPage />} />
    <Route path="/" element={<AdminRoute />}>
      <Route path={RouteLink.Admin} element={<AdminPage />} />
    </Route>
  </Routes>
);

export default AppRoutes;
